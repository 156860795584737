//import shop from '../../api/shop'
import axios from 'axios';

const state = () => ({
    naverUserId: null,
    naverUserName: null,
    naverUser:null,
    npayData:[]
})


const getters = {}

const actions = {
    getNaverUser({commit},token) {
        return axios.get('/v1/nid/me', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(function (response) {
            // console.log(JSON.stringify(response.data));
            commit('SAVE_NAVERUSER', response.data.response);
            commit('SAVE_NAVERUSERNAME', response.data.response.name);
            commit('SAVE_NAVERUSERID', response.data.response.id);
        })
    },
    getNpayData({commit},naver_user_id){

        var data = JSON.stringify({
            "collection": "npay",
            "database": "naver",
            "dataSource": "Cluster0",
            "limit": 15,
            "filter": {
                "naver_id": naver_user_id
            }
        });
        var config = {
            method: 'post',
            url: '/app/data-uxbwb/endpoint/data/v1/action/find',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': '*',
                'api-key': 'yxS0VGjyJqWIVCAXF1xhKnPOAcG6i7QL1rHVtPwFehEFCoeoansRJjqfsKAP5MEY',
                'Accept': '*/*'
            },
            data: data
        };
        axios(config)
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    commit('SAVE_NPAY', response.data.documents);
                    localStorage.setItem("npay_data_count", response.data.documents.length);
                })
                .catch(function (error) {
                    console.log(error);
        });

    }
}



const mutations = {
    SAVE_NAVERUSER(state, naverUser) {
        state.naverUser = naverUser;
        //console.log('mutation ok')
    },
    SAVE_NAVERUSERNAME(state, naverUserId) {
        state.naverUserName = naverUserId;
        //console.log('mutation ok')
    },
    SAVE_NAVERUSERID(state, naverUserId) {
        state.naverUserId = naverUserId;
        //console.log('mutation ok')
    },
    SAVE_NPAY(state, npayData) {
        state.npayData = npayData;
        //console.log('mutation ok')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
