//import shop from '../../api/shop'
import axios from 'axios';

const state = () => ({
    kakaoUserId: null,
    kakaoMessage: []
})


const getters = {}

const actions = {
    loadUsers({commit},kakao_user_id) {

        var data = JSON.stringify({
            "collection": "kakao_messages",
            "database": "chat",
            "dataSource": "Cluster0",
            "limit": 10,
            "projection": { "userRequest.user.properties.app_user_id": 1,  "userRequest.params": 1, "userRequest.utterance": 1},
            "sort": { "userRequest.params.timestamp": -1 },
            "filter": {
                "userRequest.user.properties.app_user_id": kakao_user_id
            }
        });

        var config = {
            method: 'post',
            url: '/app/data-uxbwb/endpoint/data/v1/action/find',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': '*',
                'api-key': 'yxS0VGjyJqWIVCAXF1xhKnPOAcG6i7QL1rHVtPwFehEFCoeoansRJjqfsKAP5MEY',
                'Accept': '*/*'
            },
            data: data
        };
        setInterval(function() {
        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                commit('SAVE_KAKAO', response.data.documents);
                localStorage.setItem("kakao_msg_count", response.data.documents.length);
            })
            .catch(function (error) {
                console.log(error);
            });
        },1000);
    },

    getKakaoUser: function ({commit}, token) {
        return axios.get('/v2/user/me', {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${token}`
            },

        }).then(function (response) {
            //console.log(JSON.stringify(response.data));
            commit('SAVE_KAKAOUSER', response.data.id);
        }).catch(function (error) {
            localStorage.removeItem('kakao_access_token')
            console.log("Error:" + error);
        })
    }
}



const mutations = {
    SAVE_KAKAO(state, kakaoMessage) {
        state.kakaoMessage = kakaoMessage;
        //console.log('mutation ok')
    },
    SAVE_KAKAOUSER(state, kakaoUserId) {
        state.kakaoUserId = kakaoUserId;
        //console.log('mutation ok')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}