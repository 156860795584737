<i18n src="@/common/locales.json"></i18n>

<template>
  <div id="app">
    <NavBar />
    <b-progress
      v-if="progressValue < progressMax"
      :value="progressValue"
      :max="progressMax"
      show-progress
      animated
    ></b-progress>
    <transition name="fade">
      <router-view />
    </transition>
    <SolidTrackSession />

    <div id="footNav">
      <router-link to="/">{{ $t('home') }}</router-link> |
      <!-- <router-link to="/editor">{{ $t('editor') }}</router-link> | -->
      <!--      <router-link to="/simplegouv">Gouvernance</router-link> |-->
      <router-link to="/callbacknaver">{{ $t('profile') }}</router-link> |
      <!-- <router-link to="/inbox">{{ $t('inbox') }}</router-link> | -->
      <!-- <router-link to="/workspaces">{{ $t('workspace') }}</router-link> |
      <router-link to="/chat">{{ $t('chat') }}</router-link> | -->
      <router-link to="/about">{{ $t('about') }}</router-link> |
      <router-link to="/ask">{{ $t('help') }}</router-link>
      <p>
        <a
          href="http://www.sakak.co.kr"
          target="_blank"
          style="text-decoration: none; color: rgba(0,0,0,0.4); font-weight: bold"
          >{{ $t('visitsakak') }}</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    NavBar: () => import('@/components/layout/NavBar.vue'),
    SolidTrackSession: () => import('@/components/solid/SolidTrackSession'),
  },
  computed: {
    progressValue() {
      return this.$store.state.solid.progressValue;
    },
    progressMax() {
      return this.$store.state.solid.progressMax;
    },
  },
};
</script>
<style>
html {
  background-color: #fcfcfc;
}
body {
  background-color: #fcfcfc !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fcfcfc;
  max-width: 1080px;
  margin: auto;
}

#footNav {
  padding: 10px;
}
#footNav a {
  font-weight: bold;
  color: #2c3e50;
}

#footNav a.router-link-exact-active {
  color: #3768b2;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
router-view {
  text-align: left;
}

.progress,
.progress-bar {
  visibility: hidden;
  margin-top: -16px;
}

#nav li > a {
  color: rgba(0, 0, 0, 1) !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}
#nav .dropdown-menu {
  border: 1px solid #dcdcdc !important;
  padding: 0.5rem !important;
  border-radius: 5px !important;
}
.valueLocal {
  border: none !important;
}
a:active,
a:focus,
a:visited {
  text-decoration: none !important;
}
</style>
