//import shop from '../../api/shop'
import axios from 'axios';

const state = () => ({
    lineUserId: null,
    users: []
})


const getters = {}

const actions = {
    loadUsers({commit},line_user_id) {

        var data = JSON.stringify({
            "collection": "line_messages",
            "database": "chat",
            "dataSource": "Cluster0",
            "limit": 10,
            "projection": { "source.userId": 1,  "timestamp": 1,"message.id": 1, "message.text": 1, "message.type": 1, "message.object": 1} ,
            "sort": { "timestamp": -1 },
            "filter": {
                "source.userId": line_user_id
            }
        });

        var config = {
            method: 'post',
            url: '/app/data-uxbwb/endpoint/data/v1/action/find',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': '*',
                'api-key': 'yxS0VGjyJqWIVCAXF1xhKnPOAcG6i7QL1rHVtPwFehEFCoeoansRJjqfsKAP5MEY',
                'Accept': '*/*'
            },
            data: data
        };

        setInterval(async function () {
            const response = await axios(config)
            commit('SAVE_USERS', response.data.documents);
            localStorage.setItem("line_msg_count", response.data.documents.length);

        },1000);
    },
    getLineUser({commit},token) {
        return axios.get('https://api.line.me/v2/profile', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(function (response) {
            // console.log(JSON.stringify(response.data));
            commit('SAVE_LINEUSER', response.data.userId);
        })
    }
}



const mutations = {
    SAVE_USERS(state, users) {
        state.users = users;
        //console.log('mutation ok')
    },
    SAVE_LINEUSER(state, lineUserId) {
        state.lineUserId = lineUserId;
        //console.log('mutation ok')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
